



























































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useScheduleSetting } from '@/composition/schedule';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';

export default defineComponent({
  name: 'Schedule',
  components: {
    FcRoleLoading,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('schedules'));

    const { error, notify } = useNotification();
    const { confirmDialog } = useConfirm();
    const setting = useScheduleSetting();

    const isSaving = ref(false);
    const saveSchedule = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        await setting.saveScheduleSetting();
        notify('更新しました。');
        await setting.getScheduleSetting();
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };
    return {
      pageTitle: 'スケジュール設定',
      myRoleSettings,
      setting,
      isSaving,
      saveSchedule,
    };
  },
});
